<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Non Green Parent Page Start From Here -->
        <ng-container *ngIf="!openNonGreenSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}}</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="NonGreenProperties.NonGreenSection!=null && NonGreenProperties.NonGreenSection!=undefined && NonGreenProperties.NonGreenSection.length > 0">
                        <ng-container
                            *ngFor="let nonGreenSection of (NonGreenProperties.NonGreenSection | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{nonGreenSection.DisplayName |
                                    translate}}</span>
                            </p>

                            <div class="gridAction">
                                <div class="d-flex justify-content-between">
                                    
                                </div>
                            </div>

                            <!-- <div class="gridContainer mb-3"> -->
                            <div class="table-responsive">
                                <ng-container
                                    *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                    <grid-dispctrl #updateStatusgrid
                                        [FieldTemplate]="NonGreenTemplate | filterBy: {Group: nonGreenSection.Name}"
                                        [GridProp]="GridProp" [GridData]="NonGreenData" [cellLock]="setCellLock"
                                        [parent]="this" [GridDataTotalCount]="NonGreenData.length"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                    </grid-dispctrl>
                                </ng-container>
                            </div>
                            <!-- </div> -->
                            <br />
                            <br />
                        </ng-container>
                    </ng-container>
                    <br>

                    <button style="float: right;" type="button" class="rounded-pill btnCustom btnPrimary" (click)="showOverallStatus()">Show/Hide Overall Status
                        <span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button>


                    <br><br>

                    <ng-container
                        *ngIf="NonGreenProperties.AdditionalSection!=null && NonGreenProperties.AdditionalSection!=undefined && NonGreenProperties.AdditionalSection.length > 0  && isAdditonalSectionClicked">
                        <ng-container
                            *ngFor="let additionalSection of (NonGreenProperties.AdditionalSection | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{additionalSection.DisplayName |
                                    translate}}</span>
                            </p>
                            <!-- <div class="gridContainer mb-3"> -->
                            <div class="table-responsive">
                                <ng-container
                                    *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                    <grid-dispctrl #updateStatusgrid
                                        [FieldTemplate]="NonGreenTemplate | filterBy: {Group: additionalSection.Name}"
                                        [GridProp]="GridProp" [GridData]="NonGreenAdditionalSectionData"
                                        [GridDataTotalCount]="NonGreenAdditionalSectionData.length"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                    </grid-dispctrl>
                                </ng-container>
                            </div>
                            <!-- </div> -->
                            <br />
                            <br />
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!--Non Green Parent Page All End Here -->


        <!--Non Green Additional Sections Starts Here-->
        <ng-container *ngIf="openNonGreenSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}} > {{sectionDisplayName| translate}}</span>
                    </h3>
                </div>
          

                <ng-container *ngIf="!viewEmailTemp">
                <div class="card-body overflow-auto">
                    <!-- <div class="gridContainer mb-3">  -->
                       
                        <!-- <div class="table-responsive"> -->
                          
              <!--- Grid section-->
                        <!-- commenting  for BGV Head role start here -->
                            <ng-container
                                *ngIf="NonGreenProperties.NonGreenSummary!=null && NonGreenProperties.NonGreenSummary!=undefined && NonGreenProperties.NonGreenSummary.length > 0
                                && (NonGreenProperties.NonGreenSummary | filterBy: {CanShow: true, SectionName : sectionName}).length > 0 &&DisplayadditionalGrid">
                                <ng-container
                                    *ngFor="let NonGreenSummary of (NonGreenProperties.NonGreenSummary | filterBy: {CanShow: true, SectionName : sectionName})">
                                    <p class="text-bold mb-1">
                                        <span>{{NonGreenSummary.DisplayName |
                                            translate}}</span>
                                    </p>

                                    <div class="gridAction">
                                        <div class="d-flex justify-content-between">
                                        </div>
                                    </div>

                                    <div *ngif="viewEmailTemp!=true" class="table-responsive">
                                        <ng-container
                                            *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                            <grid-dispctrl #additionalsectiongrid
                                                [FieldTemplate]="NonGreenTemplate | filterBy: {Group: NonGreenSummary.Name}"
                                                [GridProp]="GridProp" [GridData]="sectionDataSummary" [parent]="this"
                                                [GridDataTotalCount]="sectionDataSummary.length">
                                            </grid-dispctrl>
                                        </ng-container>
                                    </div>
                                    
                                   
                                    <br />
                                    <br />
                                </ng-container>
                            </ng-container>

                           
                            <ng-container
                                *ngIf="NonGreenProperties.NonGreenSection!=null && NonGreenProperties.NonGreenSection!=undefined && NonGreenProperties.NonGreenSection.length > 0&&DisplayadditionalGrid">
                                <ng-container
                                    *ngFor="let NonGreenSection of (NonGreenProperties.NonGreenSection | filterBy: {CanShow: true})">

                                    <ng-container
                                        *ngIf="NonGreenProperties.NonGreenSummary!=null && NonGreenProperties.NonGreenSummary!=undefined && NonGreenProperties.NonGreenSummary.length > 0
                                        && (NonGreenProperties.NonGreenSummary | filterBy: {CanShow: true, SectionName : sectionName}).length > 0">
                                        <p class="text-bold mb-1">
                                            <span>{{NonGreenSection.DisplayName |
                                                translate}}</span>
                                        </p>
                                    </ng-container>

                                    <div class="gridAction">
                                        <div class="d-flex justify-content-between">
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <ng-container
                                            *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                            <grid-dispctrl #additionalsectiongrid
                                                [FieldTemplate]="NonGreenTemplate | filterBy: {Group: sectionName}"
                                                [GridProp]="GridProp" [GridData]="sectionData"
                                                [GridDataTotalCount]="sectionData.length" (FieldClick)="onClick($event)"
                                                (FieldChange)="onChange($event)">
                                            </grid-dispctrl>
                                        </ng-container>
                                    </div>
                                    <br />
                                    <br />
                                </ng-container>
                            </ng-container>

                        <!-- commenting  for BGV Head role end here -->

                        <!-- Changes  for BGV Head role start here   [FormProp]="FormProp" -->
                    <ng-container>
                        <div class="contentScroll p-3 full">
                            <div class="row small-gutter">

                               
                                <ng-container *ngIf="NonGreenProperties.NonGreenSummary!=null && NonGreenProperties.NonGreenSummary!=undefined && NonGreenProperties.NonGreenSummary.length > 0
                                        && (NonGreenProperties.NonGreenSummary | filterBy: {CanShow: true, SectionName : sectionName}).length > 0">
                                    <div
                                     *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                        <customform #ngcustomform
                                        [FormTemplate]="NonGreenTemplate | filterBy: {Group: 'CandidateDetails'}"                                     
                                        [FormData]="NonGreenFormData"
                                        (FieldClick)="onClick($event)"
                                        (FieldChange)="onChange($event)">
                                       
                                        </customform>
                                        <br><br>
                                        <customform #ngcustomform1
                                        [FormTemplate]="NonGreenTemplate | filterBy: {Group: 'SubmitforBGVHead'}"                                     
                                        [FormData]="NonGreenFormData"
                                        [FormProp]="BasicFormProp"   
                                        (FieldClick)="onClick($event)"
                                        (FieldChange)="onChange($event)">
                                       
                                        </customform>
                                    <ng-container>
                                        
                                        <div class="card-header">
                                            <h6>
                                                <div>
                                                    <div class="d-flex justify-content-between">
                                                            <ul class="actionList" style="margin: left 10px">
                                                                <ng-container >
                                                                    <li>
                                                                        <div class="cellLink" id="add-sec">
                                                                            <a class="cellLink" aria-expanded="false" (click)="AddMoreDetails()">
                                                                                <span>{{'Add Check Details' }}</span>
                                                                            </a>
                                                                        </div>
                                                                    </li>
                                                                </ng-container>
                                                              
                                                            </ul>
                                                    </div>
                                                </div>
                            
                            
                                            </h6>
                                        </div>
                                        
                                        <ng-container *ngIf="(SubmittedDetailsList!=null && SubmittedDetailsList!=undefined && SubmittedDetailsList.length > 0)"> 
                                           <div class="card m-0 mt-3">
                                            <div class="card-body overflow-auto">
                                                <div class="accordion" [id]="'_'+sectionName">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" [id]="'sectionName'">
                                                            <button class="accordion-button collapsed justify-content-between" type="button"
                                                                data-bs-toggle="collapse" [attr.data-bs-target]="'#_sectionName_H'"
                                                                aria-expanded="false" [attr.aria-controls]="'_sectionName_H'">
                                                                <div>
                                                                    {{'Check Details'
                                                                    }}
                                                                </div>
                                                            </button>
                                                        </h2>
                                
                                                       
                                                        <div [id]="'_sectionName_H'" class="accordion-collapse collapse"
                                                            [attr.aria-labelledby]="'S_sectionName_'">
                                                            <br />
                                                            <div class="accordion-body">
                                                               
                                                                <ng-container *ngFor="let data of SubmittedDetailsList">
                                                                    <div class="row small-gutter form-inline">
                                                                        <div class="d-flex justify-content-between">
                                                                            <p class="d-flex text-bold">{{data.VerifiedComponentName |
                                                                                translate}}</p>
                                                                            <ul class="actionList" >
                                                                                <li (click)="showConfirmationforDeleteDetails(data)">
                                                                                    <a href="javascript:;">
                                                                                        <i class="ion ion-md-trash" aria-hidden="true"></i> Delete
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                       
                                                                        <br />
                                                                        <br />
                                                                        <hr class="dividerThin">
                                
                                                                        <ng-container
                                                                            *ngIf="(SubmitDetailsTemplate!=null && SubmitDetailsTemplate!=undefined && SubmitDetailsTemplate.length > 0)">
                                                                            <customform #SEC_gapsectionData [FormTemplate]="SubmitDetailsTemplate"
                                                                                [FormData]="data" [FormProp]="SubmitFormProp"
                                                                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                                                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                                                            </customform>
                                
                                                                        </ng-container>
                                
                                                                    </div>
                                                                </ng-container>
                                                                <!-- </div> -->
                                
                                                            </div>
                                                        </div>
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                           </div>
                                        </ng-container>
                                    </ng-container>
                                        <br>
                                        <br>
                                        <!-- <ng-container>
                                            <div class="card-header">
                                                <h4>
                                                    <div>
                                                        <div class="d-flex justify-content-between">
                                                            <ng-container>
                                                              
                                                                <ul class="actionList" style="margin: left 10px">
                                                                    <ng-container >
                                                                        <li>
                                                                            <div class="cellLink" id="add-sec">
                                                                                <a class="cellLink" aria-expanded="false" >
                                                                                    <span>{{'Add More  ' }}</span>
                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    </ng-container>
                                                                  
                                                                </ul>
                                
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                
                                
                                                </h4>
                                            </div>
                                         <div class="accordion" [id]="'_'+verifiedSecionName">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" [id]="verifiedSecionName">
                                                    <button class="accordion-button collapsed justify-content-between" type="button"
                                                        data-bs-toggle="collapse" [attr.data-bs-target]="'#_'+verifiedSecionName+'_H'"
                                                        aria-expanded="false" [attr.aria-controls]="'_'+verifiedSecionName+'_H'">
                                                        <div>
                                                            Verified Components
                                                        </div>
                                                    </button>
                                                </h2>
                                              
                                                <div [id]="'_'+verifiedSecionName+'_H'" class="accordion-collapse collapse"
                                                    [attr.aria-labelledby]="'S_'+verifiedSecionName+'_'">
                                                    <div class="accordion-body">
                                                        <div class="pt-4">
                                                            <ng-container>
                                                                <ng-container>
                                                                    
                                                                        <customform 
                                                                        [FormTemplate]="NonGreenTemplate | filterBy: {Group: verifiedSecionName}"                                     
                                                                        [FormData]="NonGreenFormData"
                                                                        [FormProp]="SubmitFormProp"
                                                                        (FieldClick)="onClick($event)"
                                                                        (FieldChange)="onChange($event)">
                                                                      </customform>
                                                                    
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                          </div>
                                      
                                        </ng-container>-->
                                    <ng-container>
                                        
                                        <!-- <div class="card-header">
                                            <h6>
                                                <div>
                                                    <div class="d-flex justify-content-between">
                                                
                                                            <ul class="actionList" style="margin: left 10px">
                                                                <ng-container >
                                                                    <li>
                                                                        <div *ngIf="(showaddverifiedcomp)" class="cellLink" id="add-sec">
                                                                            <a class="cellLink" aria-expanded="false" (click)="AddmoreVerifiedComponents()">
                                                                                <span>{{'Add Verified Components' }}</span>
                                                                            </a>
                                                                        </div>
                                                                    </li>
                                                                </ng-container>
                                                              
                                                            </ul>
                                                    </div>
                                                </div>
                            
                            
                                            </h6>
                                        </div> -->
                                        <!-- <ng-container *ngIf="(VerifiedComponentsList!=null && VerifiedComponentsList!=undefined && VerifiedComponentsList.length > 0)">
                                            <div class="card m-0 mt-3">
                                                <div class="card-body overflow-auto">
                                                    <div class="accordion" [id]="'_'+verifiedSecionName">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" [id]="'verifiedSecionName'">
                                                                <button class="accordion-button collapsed justify-content-between" type="button"
                                                                    data-bs-toggle="collapse" [attr.data-bs-target]="'#_verifiedSecionName_H'"
                                                                    aria-expanded="false" [attr.aria-controls]="'_verifiedSecionName_H'">
                                                                    <div>
                                                                        {{'Verified Components'
                                                                        }}
                                                                    </div>
                                                                </button>
                                                            </h2>
                                    
                                                           
                                                            <div [id]="'_verifiedSecionName_H'" class="accordion-collapse collapse"
                                                                [attr.aria-labelledby]="'S_verifiedSecionName_'">
                                                                <br />
                                                                <div class="accordion-body">
                                                                
                                                                    <ng-container *ngFor="let data of VerifiedComponentsList">
                                                                        <div class="row small-gutter form-inline">
                                                                            <div class="d-flex justify-content-between">
                                                                                <p class="d-flex text-bold">{{data.VerifiedComponentName |
                                                                                    translate}}</p>
                                                                                <ul class="actionList" >
                                                                                    <li (click)="showConfirmationforDeleteVerifiedComp(data)">
                                                                                        <a href="javascript:;">
                                                                                            <i class="ion ion-md-trash" aria-hidden="true"></i> Delete
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                           
                                                                            <br />
                                                                            <br />
                                                                            <hr class="dividerThin">
                                    
                                                                            <ng-container
                                                                                *ngIf="(VerifiedComponentsTemplate!=null && VerifiedComponentsTemplate!=undefined && VerifiedComponentsTemplate.length > 0)">
                                                                                <customform #SEC_gapsectionData1 [FormTemplate]="VerifiedComponentsTemplate"
                                                                                    [FormData]="data" [FormProp]="SubmitFormProp"
                                                                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                                                                </customform>
                                    
                                                                            </ng-container>
                                    
                                                                        </div>
                                                                    </ng-container>
                                                                   
                                    
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container> -->
                                        
                                        <!-- </ng-container> -->
                                        <!-- </ng-container> -->
                                    </ng-container>

                                    </div>
                                 
                                     
                                      
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                          <!-- Changes  for BGV Head role end here -->
                </div>
            </ng-container> 
            </div>
        </ng-container>
        <!--Non Green Additional Sections Ends Here-->
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <ng-container
                    *ngIf="NonGreenProperties.ActionButtons != null && NonGreenProperties.ActionButtons != undefined && !IsReadonly">

                    <!--Non Green Parent Page buttons-->
                    <ng-container *ngIf="!openNonGreenSection">
                        <ng-container *ngFor="let actionBar of (NonGreenProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'NonGreen'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                        aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>

                    <!--Non Green Section Page buttons-->
                    <ng-container *ngIf="openNonGreenSection">
                        <ng-container
                            *ngFor="let actionBar of (NonGreenProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :sectionName}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                        aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>


                </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!--Attachment section-->
<ng-container *ngIf="selectedTab!='CreateEmail' && DisplayAttachment==true">
    <file-upload [Module]="uploadModule" [RefId1]="RequestId" [RefId2]="AttachmentItemData.CheckId"
        [RefId3]="AttachmentItemData.DocumentTypeId" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId]="AttachmentItemData.CandidateId" [IsMultiple]="false">
    </file-upload>
</ng-container>

<!--Attachment section-->
<ng-container *ngIf="selectedTab=='CreateEmail' && DisplayAttachment">
    <file-upload [Module]="'Mail'" [RefId1]="RequestId" [RefId2]="" [RefId3]="" [RefId4]="" [IsMultiple]="true"
        (close)="closeAttachmentEmail($event)" [Permissions]="AttachPerm" [UploadType]="" [CandidateId]="">
    </file-upload>
</ng-container>

<!--Modelpop fro email-->
<modal-popup id="EmailPopup" style="display: none;" class="modal-body_email" [modaldetail]="{'header':'Non Green Email','height':'580','width':'800'}" >
    <ng-container *ngIf="viewEmailTemp" style="width:100%;"> 
        <div class="modal-body modal-body_email" style="width:100%">
            <div  class="card-body overflow-auto" style="width:100%;">
            <customform #emailcustform style="width:100% ;"
            [FormTemplate]="NonGreenTemplate | filterBy: {Group: ('CreateEmail_form')}"
            [FormData]="selectedInfoData" [FormProp]="FormProp"
            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
        <!-- <label for="html"> {{selectedInfoData.MailText}}
       </label>
       <p [textContent]=selectedInfoData.MailText></p> -->
       <label for="lname" style="font-size:16px;"><b>Message </b><label style="color:red;">*</label></label>
       <div Id="divmailtext" contenteditable="true" [innerHtml]=selectedInfoData.MailText1  (change)="changeFn($event)"></div>
       
         </div>

<!--Attachment section-->
<ng-container *ngIf="selectedTab=='CreateEmail' && DisplayAttachment">
    <file-upload [Module]="'Mail'" [RefId1]="RequestId" [RefId2]="" [RefId3]="" [RefId4]="" [IsMultiple]="true"
        (close)="closeAttachmentEmail($event)" [Permissions]="AttachPerm" [UploadType]="" [CandidateId]="">
    </file-upload>
</ng-container>

        <ng-container  *ngIf="NonGreenProperties.Emailbuttons != null && NonGreenProperties.Emailbuttons != undefined && NonGreenProperties.Emailbuttons.length > 0">

                            <div class="d-flex justify-content-start">

                                <ng-container
                                    *ngFor="let actionBar of Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                                 | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="btnClick(actionBar)"
                                        [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                        <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                class="ion ion-md-arrow-forward"
                                                aria-hidden="true"></i></span>
                                    </button>

                                </ng-container>

                            </div>
                            <div> <h5 style="color:red">Note:-Correct format of To and CC section email Id’s by  semi-colon (;) and mentioning only the email Id without any brackets etc. (eg: abc@capgemini.com;xyz@capgemini.com)</h5></div>
                        </ng-container>
                        <br>
                        <!--Attachment Display section-->
                        <ng-container
                            *ngIf="selectedTab=='CreateEmail' && !DisplayAttachment && fileData.length>0 && fileData!=null ">

                           

                            <div class="table-responsive">
                                <table class="table table-sm table-bordered tblGrid fixed">
                                    <caption class="sr-only">File Attachments Grid</caption>
                                    <thead class="tblGridHeader">
                                        <th width="40" scope="col">#</th>
                                        <th class="text-truncate my-0" scope="col">File Name</th>
                                        <th class="text-truncate my-0" scope="col">Created Date</th>
                                        <th class="text-truncate my-0" scope="col">Created By</th>
                                        <th width="75" scope="col">&nbsp;</th>
                                    </thead>
                                    <tbody
                                        *ngIf="fileData!=null &&  fileData!=undefined && fileData.length>0">
                                        <tr *ngFor="let item of fileData; let item_indx=index">
                                            <td>
                                                <p class="text-truncate my-0">{{item_indx+1}}</p>
                                            </td>
                                            <td>
                                                <p class="text-truncate my-0"><a
                                                        [attr.aria-label]="item?.FileName"
                                                        href="javascript:;" class="cellLink"
                                                        title="{{ item.FileName}}">{{
                                                        item.FileName}}</a></p>
                                            </td>
                                            <!-- <td>
                                                <p class="text-truncate my-0" title="{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}">{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}</p>
                                                </td> -->
                                            <td>
                                                <p class="text-truncate my-0"
                                                    title="{{ item.CreatedName}}">
                                                    {{ item.CreatedDate | date: 'dd/MM/yyyy'}}</p>
                                            </td>

                                            <td>
                                                <p class="text-truncate my-0"
                                                    title="{{ item.CreatedBy}}">
                                                    {{ item.CreatedBy}}</p>
                                            </td>
                                            <td>
                                                <ul class="actionList" style="text-align: center">
                                                    <li>
                                                        <!-- [tooltip]="('Click here to delete'| translate)" container="body" placement="top" -->
                                                        <a aria-label="Delete" href="javascript:;"
                                                            *ngIf="AttachPerm.Upload">

                                                            <i class="fa fa-trash"
                                                                (click)="showDeleteConfirmation(item,item_indx)"></i></a>
                                                    </li>
                                                </ul>
                                                <!-- <p><button class="btn btn-primary" *ngIf="Permissions.Upload&&item.IsDeletable" (click)="showDeleteConfirmation(item)"><i class="fa fa-trash"></i></button></p> -->
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody
                                        *ngIf="fileData==null ||  fileData==undefined || fileData.length==0">
                                        <tr>
                                            <td colspan="5">No files attached.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>



                        </ng-container>
        
          </div>  
          <ng-container *ngIf="viewEmailTemp || selectedTab=='CreateEmail'">
            <div class="filters buttonHolder">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <ng-container
                            *ngIf="NonGreenProperties.Emailbuttons != null && NonGreenProperties.Emailbuttons != undefined && NonGreenProperties.Emailbuttons.length > 0">
                            <ng-container *ngFor="let actionBar of NonGreenProperties.Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                              | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
        
        
                                <button type="button" [ngClass]="actionBar.CustomClass" (click)="btnClick(actionBar)"
                                    [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                    translate
                                    }}
                                    <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                            aria-hidden="true"></i></span>
                                </button>
        
        
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
          </ng-container>
          <!-- Notification section -->
         <div *ngIf="displayNotifybox">
         <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
         (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
         </div>



</modal-popup>

<modal-popup id="ReinitiateCaseNG" style="display: none;"  [modaldetail]="{'header':'Reinitiate Case'}">

    <ng-container *ngIf="viewReinitiateCase" style="width:100%;">
        <div class="modal-body" style="width:100%">
          <div  class="card-body overflow-auto" style="width:200%;">
            <customform #ReinitiateCaseformNG style="width:100%;"
                [FormTemplate]="ReinitiateCaseTemplate" [FormData]="ReinitiateCaseData" [FormProp]="FormPropReinitiateCase"
                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
            </customform>
             </div>
        </div>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary"
                (click)="reinitiateCase()" value="{{'Submit' | translate }}">
                {{'Submit' | translate }}
            </button>
        </div>
    </div>

</modal-popup>

